const getters = {
  getWeb3: state => {
    return state.web3;
  },
  getUserAccount: state => {
    return state.userAccount;
  },
  getMintingInstance: state => {
    return state.mintingInstance;
  }
};

export default getters;
