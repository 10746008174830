<template>
  <div id="app">
    <router-view />
  </div>
</template>


<script>
export default {
  name: "App",
  data() {
    return {};
  },
};
</script>

<style>
@import "./css/styles.css";
</style>
