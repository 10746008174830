<template>
  <div class="minting_bg">
    <div class="card">
      <div>
        <template v-if="deadline > 0">
          <h3 class="text-center font-weight-bold mb-3">Starts in:</h3>
          <vue-countdown
            :time="deadline"
            v-slot="{ days, hours, minutes, seconds }"
          >
            <div class="time-content">
              <p id="Days" class="box-time">{{ days }}</p>
              <p id="Hours" class="box-time">{{ hours }}</p>
              <p id="Minutes" class="box-time">{{ minutes }}</p>
              <p id="Seconds" class="box-time">{{ seconds }}</p>
            </div>
            <div class="time-content">
              <small>Days</small>
              <small>Hours</small>
              <small>Minutes</small>
              <small>Seconds</small>
            </div>
          </vue-countdown>
        </template>
        <template v-else>
          <h3 class="text-white text-center font-weight-bold mb-3">Minting</h3>
        </template>
        <div>
          <div class="box">
            <strong class="py-3"> Min: 1 - Max: 6 </strong>
          </div>
          <div>
            <input
              v-if="deadline > 0"
              type="text"
              v-model="secret"
              placeholder="Secret Code for Pre Minting"
            />
          </div>
          <div>
            <input
              v-if="getUserAccount"
              type="number"
              v-model="mintAmount"
              placeholder="Enter Minting amount"
            />
          </div>
          <div class="text-center">
            <button
              v-if="getUserAccount"
              class="btn btn-primary p-3 bordered"
              @click="onMinting"
              :disabled="isLoading"
            >
              {{ isLoading ? "Confirming..." : "MINT NOW" }}
            </button>
            <button v-else class="btn btn-primary p-3" @click="onConnect">
              Connect Wallet
            </button>
            <div class="d-flex justify-content-center my-4">
              <crossmint-pay-button 
                collectionTitle="Coolshihtzu" 
                collectionDescription="Minting 1 NFT"
                collectionPhoto="https://coolshihtzu.com/favicon.ico" 
                clientId="f7eae5a5-d2cb-4e3d-b5e6-033f71e17821"
                mintConfig='{"price": "240", "_mintAmount": "1"}'
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <img class="minting_lg" src="../assets/minting_lg.png" />
    <img class="minting_sm sm" src="../assets/minting_sm.png" />
  </div>
</template>

<script>
import Web3 from "web3";
import Web3Modal from "web3modal";
import contract from "../contracts/ABIs.json";
import VueCountdown from "@chenfengyuan/vue-countdown";

import { mapActions, mapGetters } from "vuex";

export default {
  name: "App",
  components: { VueCountdown },
  data() {
    return {
      web3Modal: "",
      secret: "",
      mintAmount: "",
      isLoading: false,
      allSecrets: [
        "IBM-1911",
        "Accenture-1989",
        "New-York-42ST",
        "New-Jersey-1956",
        "Consulting-1995",
        "HP-Garage",
        "HBCU-1908",
        "Feed-Hungry-Now",
        "Great-Ideas-2025",
        "USA-Asia-Smart",
      ],
    };
  },
  beforeMount() {
    this.web3Modal = new Web3Modal({
      cacheProvider: false,
      disableInjectedProvider: false,
    });
  },
  mounted() {},
  methods: {
    ...mapActions("wallet", [
      "SET_WEB3",
      "SET_USER_ACCOUNT",
      "SET_MINTING_INSTANCE",
    ]),
    addrTruncation(addr) {
      return addr.slice(0, 6) + "…" + addr.slice(addr.length - 4, addr.length);
    },
    disconnect() {
      this.SET_WEB3(null);
      this.SET_USER_ACCOUNT(null);
    },
    async onConnect() {
      if (window.ethereum) {
        let provider = await this.web3Modal.connect();
        this.onProvider(provider);
      } else {
        this.$toasted.show("Install Metamask Wallet!");
      }
    },
    async onProvider(provider) {
      let web3 = new Web3(provider);
      this.SET_WEB3(web3);

      let accounts = await web3.eth.getAccounts();
      this.SET_USER_ACCOUNT(accounts[0]);

      let MINTING_INSTANCE = new web3.eth.Contract(
        contract.MINTING_ABI,
        contract.MINTING_ADDR
      );
      this.SET_MINTING_INSTANCE(MINTING_INSTANCE);
    },

    async onMinting() {
      if (this.deadline > 0 && !this.allSecrets.includes(this.secret)) {
        this.$toasted.show("Invalid Secret");
        return;
      } else if (Number(this.mintAmount) < 1) {
        this.$toasted.show("Minimum minting limit is 1");
        return;
      } else if (Number(this.mintAmount) > 6) {
        this.$toasted.show("Maximum minting limit is 6");
        return;
      }

      let cost_wei = await this.getMintingInstance.methods.cost().call();
      console.log(cost_wei);

      let cost = this.getWeb3.utils.fromWei(cost_wei.toString(), "ether");
      console.log(cost);

      let mintPrice = Number(this.mintAmount) * Number(cost);
      let value = this.getWeb3.utils.toHex(
        this.getWeb3.utils.toWei(mintPrice.toString(), "ether")
      );
      this.isLoading = true;
      this.getMintingInstance.methods
        .mint(Number(this.mintAmount))
        .send({
          from: this.getUserAccount,
          to: contract.MINTING_ADDR,
          value: value,
        })
        .on("transactionHash", (hash) => {
          console.log("Transaction hash", hash);
          this.$toasted.show("Transaction is submitted to network!");
        })
        .on("receipt", (receipt) => {
          this.isLoading = false;
          this.$toasted.show("Transaction completed successfully");
          console.log("Receipt: ", receipt);
        })
        .on("error", (error) => {
          this.isLoading = false;
          this.$toasted.show("Transaction rejected");
          console.log("Error receipt: ", error);
        });
    },
    humanized(number, fix) {
      return Number(
        this.getWeb3.utils.fromWei(number.toString(), "ether")
      ).toFixed(number == 0 ? 3 : fix);
    },
  },
  computed: {
    ...mapGetters("wallet", [
      "getWeb3",
      "getUserAccount",
      "getMintingInstance",
    ]),
    deadline() {
      return 1651103999000 - new Date().getTime();
    },
  },
};
</script>