<template>
  <div class="container">
    <nav class="navbar fixed-top navbar-expand-lg navbar-light bg-light">
      <div class="container">
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup"
          aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
          <div class="navbar-nav">
            <ul class="top">
              <li data-toggle="collapse" data-target=".navbar-collapse.show">
                <a class="nav-item nav-link" href="#" @click.prevent="goto('home')">Home</a>
              </li>
              <li data-toggle="collapse" data-target=".navbar-collapse.show">
                <a href="https://opensea.io/collection/coolshihtzunyc" class="nav-item nav-link">Opensea</a>
              </li>
            </ul>
          </div>
        </div>
        <div class="ml-auto">
          <a @click.prevent="goto('minting')" class="btn btn-outline-primary p-2">Minting</a>
        </div>
      </div>
    </nav>
    <div class="row" id="home">
      <div class="col-12">
        <img src="@/assets/Homepage.png" alt="Coolshihtzu NFT" style="max-width: 100%" />
      </div>
      <div class="col-12 mt-5">
        <h1 class="large">The Coolshihtzu Membership Card</h1>
        <p>
          The Coolshihtzu is a digital membership card that uses blockchain technology to build a community around the
          <a href="https://www.downsizeus.com/" target="_blank">www.downsizeus.com</a> online marketplace. The
          marketplace is focused on the sustainability offered by recirculating well-made clothing and repurposing
          household items.
          <br><br>
          In addition, as the baby boomer generation ages, an organized marketplace for things cumulated over the years
          is critical–landfills are not an option.
        </p>
      </div>
      <section>
        <div class="col-12">
          <h1>WELCOME TO THE WORLD OF COOLSHIHTZU</h1>
          <p>
            We’re building an organized community to support the marketplace. The platform starts with 15,000 membership
            cards stored on the Polygon blockchain. Each card offers access to extra benefits. Those benefits will
            include digital skills training and marketplace support for ideas, products, and services that create value
            in local communities.
            <br><br>
            The community is represented by Coolshihtzu, a fresh cast of Disney-like characters designed to be diverse
            and inclusive. The world is shifting towards membership-based communities that work together to create
            opportunities.
          </p>
          <div class="sm-center my-5">
            <a href="https://discord.com/invite/aFUntRhE72" class="btn btn-primary p-3">
              JOIN DISCORD
            </a>
            <a href="https://twitter.com/COOLSHIHTZU" class="btn btn-danger p-3 ml-4">
              TWITTER
            </a>
          </div>
          <br />
          <br />
          <h1>The Membership Card</h1>

          <p>
            The character traits in each card reflect our history, memories, and core values. We’re using artistic
            expression to build a new character and social influencer within the digital membership card space.
            <br><br>
            Examples of the marketplace membership cards are shown at the <a
              href="https://opensea.io/collection/coolshihtzu">Coolshihtzu Hall of Fame.</a>
          </p>
        </div>
      </section>
      <section>
        <div class="col-12 cls_1">
          <carousel :loop="true" :autoplay="true" :navigationEnabled="true" :scrollPerPage="false" :speed="2000"
            :autoplayTimeout="7000" :perPage="3">
            <slide>
              <div class="mx-4">
                <img alt="Coolshihtzu NFT" src="@/assets/1.png" />
              </div>
            </slide>
            <slide>
              <div class="mx-4">
                <img alt="Coolshihtzu NFT" src="@/assets/2.png" />
              </div>
            </slide>
            <slide>
              <div class="mx-4">
                <img alt="Coolshihtzu NFT" src="@/assets/3.png" />
              </div>
            </slide>
            <slide>
              <div class="mx-4">
                <img alt="Coolshihtzu NFT" src="@/assets/4.png" />
              </div>
            </slide>
            <slide>
              <div class="mx-4">
                <img alt="Coolshihtzu NFT" src="@/assets/5.png" />
              </div>
            </slide>
            <slide>
              <div class="mx-4">
                <img alt="Coolshihtzu NFT" src="@/assets/6.png" />
              </div>
            </slide>
          </carousel>
        </div>
        <div class="col-12 cls_2">
          <carousel :loop="true" :autoplay="true" :navigationEnabled="false" :scrollPerPage="false" :speed="2000"
            :autoplayTimeout="7000" :perPage="1">
            <slide>
              <div class="mx-4">
                <img alt="Coolshihtzu NFT" src="@/assets/1.png" />
              </div>
            </slide>
            <slide>
              <div class="mx-4">
                <img alt="Coolshihtzu NFT" src="@/assets/2.png" />
              </div>
            </slide>
            <slide>
              <div class="mx-4">
                <img alt="Coolshihtzu NFT" src="@/assets/3.png" />
              </div>
            </slide>
            <slide>
              <div class="mx-4">
                <img alt="Coolshihtzu NFT" src="@/assets/4.png" />
              </div>
            </slide>
            <slide>
              <div class="mx-4">
                <img alt="Coolshihtzu NFT" src="@/assets/5.png" />
              </div>
            </slide>
            <slide>
              <div class="mx-4">
                <img alt="Coolshihtzu NFT" src="@/assets/6.png" />
              </div>
            </slide>
          </carousel>
        </div>
      </section>
      <section id="minting">
        <div class="col-12">
          <h1>Minting the Membership Card</h1>
          <p>
            It’s an online marketplace membership for the socially conscious, priced at <a
              href="https://www.coinbase.com/converter/matic/usd" target="_blank">195 MATIC based on the current
              exchange rate.</a> The membership value will float based on the current MATIC exchange rate. Please click
            the link for the current dollar exchange rate. Add about 15% for related fees charged by the service
            provider you select.
            <br><br>
            Purchase MATIC at one of the coin exchanges below. Use the secured Stripe debit or credit card process if
            you’re new to the world of digital assets.
            <br><br>
            If you’re not using a credit or debit card, you’ll need to use <a href="https://metamask.io/">MetaMask</a>
            to create your marketplace membership card. Install the Chrome or Foxfire extension or download the mobile
            app.
            <br><br>
            Use a coin exchange like <a href="https://www.coinbase.com/">Coinbase</a>,
            <a href="https://www.gemini.com/">Gemini</a> , or a service like
            <a href="https://www.moonpay.com/">MoonPay</a> to acquire the MATIC for your purchase. Don’t forget to add a
            small amount of gas to complete your blockchain transaction. Welcome to the community!
            <br><br>

          </p>
          <Minting />
        </div>
      </section>
      <section class="pb-5">
        <div class="col-12">
          <h1 class="large">Terms of Ownership</h1>
          <p>
            Ownership of your purchased Coolshihtzu NFT grants you personal
            rights to use it as a profile picture or in a virtual world
            represented on the internet. To ensure a quality project and enhance
            the value of your purchase we’ll review and support community ideas
            for derivative works and commercial usage.
            <br /><br />
            To ensure quality and standards, the Coolshihtzu team will provide
            access to printing services and offer a process for you to sell
            limited editions of your NFT works. Usage of the Coolshihtzu name
            and related branding is prohibited.
          </p>
          <br />
          <a href="" @click.prevent="$router.push('/terms')">Read the full terms</a>
        </div>
      </section>
    </div>
  </div>
</template>


<script>
import Minting from "./Minting.vue";
export default {
  name: "App",
  components: { Minting },
  data() {
    return {
    };
  },
  mounted() {
    document.addEventListener("click", this.toggleDropdown);
  },
  methods: {
    toggleDropdown(event) {
      var dropdown = document.getElementById("navbarNavAltMarkup");
      if (event.target.classList.contains("urunler")) {
        dropdown.classList.toggle("show");
      } else {
        dropdown.classList.remove("show");
      }
    },
    goto(id) {
      document.getElementById(id).scrollIntoView({ behavior: "smooth" });
    },
  },
};
</script>