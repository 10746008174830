<template>
  <div class="container">
    <nav class="navbar fixed-top navbar-expand-lg navbar-light bg-light">
      <div class="container">
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup"
          aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
          <div class="navbar-nav">
            <ul class="top">
              <li data-toggle="collapse" data-target=".navbar-collapse.show">
                <a class="nav-item nav-link" href="#" @click.prevent="goto('home')">Home</a>
              </li>
              <li data-toggle="collapse" data-target=".navbar-collapse.show">
                <a href="https://opensea.io/collection/coolshihtzunyc" class="nav-item nav-link">Opensea</a>
              </li>
            </ul>
          </div>
        </div>
        <div class="ml-auto">
          <a href="#" @click.prevent="goto('minting')" class="btn btn-outline-primary p-2">Minting</a>
        </div>
      </div>
    </nav>
    <section class="mt-5">
      <div class="col-12">
        <h1 class="large">Terms of Ownership</h1>
        <p><br /><br /></p>
        <p>
          Ownership of your purchased Coolshihtzu NFT grants you personal rights
          to use it as a profile picture or in a virtual world represented on
          the internet. To ensure a quality project and enhance the value of the
          purchase, we’ll support community ideas for derivative works and
          commercial usage.
          <br /><br />

          To ensure quality and standards, the Coolshihtzu team will provide
          access to printing services and offer a process for you to sell
          limited editions of your NFT works. Usage of the Coolshihtzu name and
          related branding is prohibited.
          <br /><br />

          Coolshihtzu is a collection of NFTs running on the Polygon network.
          The Coolshihtzu website is designed as an ordering entry process using
          digital wallets. Opensea is the hosting service for Coolshihtzu NFT
          tokens. Users are entirely responsible for the safety and management
          of their own private wallets and validate that Coolshihtzu.com is the
          website used for the minting process. All purchases are final, and
          there’s no ability to undo, reverse, or restore any transactions.
          <br /><br />

          This website and its connected services are provided "as is" and "as
          available" without warranty of any kind. By using this website, you
          are accepting sole responsibility for any and all transactions
          involving the Coolshihtzu digital collectibles.
          <br /><br />

          Below, we have summarized the key terms applicable to the purchase of
          a Coolshihtzu NFT and the use of the digital art ("Art") linked to it.
          This is not intended to supplement or modify the agreement. Rather, it
          is to help you understand the key terms applicable to it.

          <br /><br />
        </p>

        <h1 class="mt-5 mb-2">Ownership and License</h1>
        <h1 class="mt-5 mb-2">1. You own your purchased NFT</h1>
        When you purchase an NFT, you own the underlying NFTS, the digital
        record on the blockchain, completely. Ownership of the NFT is mediated
        entirely by the Smart Contract and the Polygon Network. At no point may
        we seize, freeze, or otherwise modify your ownership. You do not own the
        "art" linked to the NFT, which is instead licensed to you as described
        below.
        <h2>Personal Use</h2>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Subject to your continued compliance with
        these terms, you are granted a worldwide, royalty-free license to use,
        copy, and display the art, solely for personal, non-commercial use and
        in the Opensea marketplace that permits the purchase and selling of your
        Coolshihtzu NFT.
        <h2>For Commercial Use</h2>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Subject to your continued compliance with
        these terms, Coolshihtzu grants you the right to sell a limited edition
        set of your artwork using Coolshihtz printing services and rules.
        Commercialization of the Coolshihtzu NFT beyond printing wall art and
        other items as described must be approved in advance by the Coolshihtzu
        team.
        <h2>Trademark and Intellectual Property</h2>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Nothing herein gives you any rights to any
        other trademarks or other intellectual property rights belonging to
        Coolshihtzu or America Transformation and their associated logos. All of
        these rights are expressly reserved by the creator.

        <h1 class="mt-5 mb-2">2. Your Obligations</h1>
        You are solely responsible for your use of the Coolshihtzu and any
        consequences that may arise as a result. For instance, and not as a
        limitation, you may not or may not permit any third party to:
        <ul>
          <br />
          <br />
          1. Alter the work of art except as expressly permitted by the terms of
          this license;
          <br />
          <br />
          2. Use the art as a brand or trademark, or to advertise, market, or
          sell any product or service, except to advertise, market, or sell any
          merchandise or works created in accordance with your Commercial Use
          license;
          <br />
          <br />
          3. Use the artwork in conjunction with media or merchandise that
          promotes hate speech, illegal activity, vulgarity (e.g., pornography),
          offensive behavior, violence, cruelty, or political statements;
          <br />
          <br />
          4. Make any attempt to register a trademark, copyright, or other
          intellectual property rights in or to the art;
          <br />
          <br />
          5. (Represent or imply that your Commercial Use license exercise is
          endorsed or affiliated with Coolshihtzu or any other associated
          property;
          <br />
          <br />
          6. Make defamatory or dishonest statements about Coolshihtzu, or
          otherwise attempt to harm Coolshihtzu's goodwill or reputation;
          <br />
          <br />
          7. Use the artwork to generate more non-fungible tokens or other
          digital assets that can be tracked on a distributed ledger;
          <br />
          <br />
          8. Create derivative works of art that include new characters or
          replicas of art featured in Coolshihtzu NFTs that you do not own;
          <br />
          <br />
          9. Use the art as a trademark or brand, or for any other fundraising,
          advertising, marketing, or similar purposes (other than to advertise
          or market the works created under your Commercial Use license);
          <br />
          <br />
          10. Use any Coolshihtzu or America Transformation trademark in
          connection with your Commercial Use license exercise; and
          <br />
          <br />
          11. Use or attempt to register any asset, including any domain names,
          social media accounts or related addresses, that contains or
          incorporates any artwork, other representation, name or mark that may
          be confusingly similar to such trademarks.
          <br />
          <br />
        </ul>

        <h1 class="mt-5 mb-2">3. Fees and Payment</h1>
        <p>
          When you purchase a Coolshihtzu using Coolshihtzu.com primary and
          secondary sales on Opensea, you are responsible for all activity
          related to your purchase.
        </p>

        <h1 class="mt-5 mb-2">
          4. Risk Assumption and Limitation of Liability
        </h1>
        <p>
          You accept and acknowledge all risks associated with any Coolshihtzu
          NFT that you purchase, including (but not limited to) security, drops
          in value, failures of blockchain technology, legal and regulatory
          risk, and the risks of using third-party platforms.
          <br />
          If we are somehow liable to you in connection with a Coolshihtzu NFT,
          our liability will be limited to direct damages and will not exceed
          $1,000 USD.
        </p>

        <h1 class="mt-5 mb-2">5. Indemnification</h1>
        <p>
          You will be fully responsible for any claims against Coolshihtzu, its
          licensors or affiliates, related to:
        </p>
        <ul class="list">
          <li>your breach of the terms</li>
          <li>your use of the marketplace</li>
          <li>your execution of the license to the Art.</li>
        </ul>
        <h1 class="mt-5 mb-2">6. Dispute Resolution; Arbitration</h1>
        <p>
          Any dispute related to your Coolshihtzu will be governed by the laws
          of New Jersey. If a dispute requires formal proceedings to resolve,
          the dispute will be resolved through binding arbitration, unless a
          party elects to pursue an eligible claim in small claims court or a
          party seeks injunctive relief.
        </p>

        <div class="row">
          <div class="col-12">
            <div class="text-center mt-5">
              <p>
                Coolshihtzu by Gregory Adkins for America Transformation. © 2022
                All Rights Reserved
              </p>
            </div>
          </div>
        </div>
        <br />
      </div>
    </section>
  </div>
</template>


<script>
export default {
  name: "Terms",
  data() {
    return {};
  },
  methods: {
    goto(id) {
      this.$router.push("/");
      setTimeout(() => {
        document.getElementById(id).scrollIntoView({ behavior: "smooth" });
      }, 1000);
    },
  },
};
</script>